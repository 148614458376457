.unauth { position:fixed; top:0; left:0; width:100%; height:100%; display:flex; padding:12px; }
.unauth > div { margin:auto; background:#FFFFFF; box-shadow:0 2px 4px rgba(165, 163, 174, 0.3); border-radius:6px; width:100%; max-width:440px; }
.unauth .navbar { height:auto; }
.unauth .navbar > div { height:auto; position:relative; width:100%; padding:0; backdrop-filter:none; }
.unauth .navbar nav .logo { margin-left:24px; }
.unauth .content { padding:24px; }

.unauth .content .input { width:100%; }

body.dark .unauth > div { background:#2F3349; }
