.splash_screen
{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.status_label
{
  display: flex;
  align-items: center;
}

.status_icon
{
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.gray
  {
    background: rgba($black-color, 0.1);
  }

  &.blue
  {
    background: rgba(0, 207, 232, 0.16);
    fill: blue;
    color: blue;
  }

  &.red
  {
    background: rgba(234, 84, 85, 0.16);
    fill: red;
    color: red;
  }

  &.green
  {
    background: rgba(40, 199, 111, 0.16);
    fill: green;
    color: green;
  }
}

fieldset
{
  border: none;
  .input
  {
    width: 100%;
  }
  hr
  {
    border: 1px solid #eee;
  }
}

.token_with_logo
{

  img
  {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}



.placeholder
{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  flex-direction: column;
}

.with_icon
{
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  svg
  {
    margin-right: 5px;
  }
}

.data-block
{

  &.left
  {
    flex-grow: 1;
    max-width: 100%;
  }

  &.right
  {
    padding: 14px 24px 24px;
    width: auto;
    max-width: 250px;
    flex-grow: 1;

    .btn
    {
      width: 100%;
      margin-top: 10px;
      display: block;
    }
  }
}

.copy-to-clipboard {
  cursor: pointer;
  color: #7367F0;

  &.copied {
    opacity: .8;
    animation: blur-in-out 1s
  }
}

fieldset[disabled]
{
  input
  {
    border-color: transparent !important;
    padding-left: 0;

    &::placeholder
    {
      opacity: 0;
    }
  }
  .switch
  {
    display: none;
  }
}

body.dark
{
  .status_icon
  {

    &.gray
    {
      background: rgba($black-color, 0.1);
    }

    &.blue
    {
      background: rgba(#1e1ef6, 0.2);
      fill: #1e1ef6;
      color: #1e1ef6;
    }

    &.red
    {
      background: rgba(234, 84, 85, 0.16);
      fill: red;
      color: red;
    }

    &.green
    {
      background: rgba(40, 199, 111, 0.16);
      fill: #42cd42;
      color: #42cd42;
    }
  }
}


  @keyframes blur-in-out {
    0% {filter: blur(0px)}
    50% {filter: blur(1px)}
    100% {filter: blur(0px)}
  }

  .btn-placeholder, .text-row-placeholder
  {
    animation: placeholder-pulse 1.5s infinite;
    border-color: #eeeeee;
    background-color: #eeeeee;
  }


@keyframes placeholder-pulse {
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
}
.dropdown-toggler
{
  position: relative;
}

.action_icons
{
  font-size: 0.8rem;
  display: none;
  position: absolute;
  top: 25px;
  left: 10px;

  border: 1px solid #E8E8EA;
  z-index: 999;
  background-color:#F8F7FA;

  .action
  {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8E8EA;
    padding: 10px;

    &:hover
    {
      background-color:#E8E8EA;
    }
  }
}

.dropdown-toggler.visible
{
  .action_icons
  {
    display: block;
  }
}

body.dark .action_icons
{
  background-color:#2F3349;

  .action
  {
    &:hover
    {
      background-color: #24283B;
    }
  }
}

.hoverable
{
  cursor: pointer;
  &:hover
  {
    opacity: .9;
  }
}