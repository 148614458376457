ul.pagination {
  display:flex;
  justify-content:flex-end;
  li
  {
    margin:0 2px;
    a
    {
      display:block; padding:6px 8px; min-width:30px; text-align:center; background:rgba(75, 70, 92, 0.08); border-radius:6px;
      cursor: pointer;

      &:hover
      {
        background:rgba(75, 70, 92, 0.2)
      }

      &.active
      {
        background:#7367F0 !important;
        color: $white-color;
      }

      &.disabled
      {
        pointer-events: none;
        opacity: .8;
      }
    }
  }
}
@media only screen and (max-width : 992px) {
  ul.pagination { justify-content:center; }
}
@media only screen and (max-width : 480px) {
  ul.pagination li:not(:first-child):not(:last-child) { display:none; }
}
body.dark ul.pagination li a {
  background:rgba(75, 70, 92, 0.5);

  &:hover
  {
    background:rgba(75, 70, 92, 0.2)
  }
}
