.tr-enter {
  opacity: 0;
}

.tr-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.tr-exit {
  opacity: 1;
}

.tr-exit-active {
  opacity: 0;
  background-color: beige;
  transition: opacity 500ms ease-in;
}

table.sm
{
  td{
    padding: 5px
  }
}

td
{
  position: relative;
}