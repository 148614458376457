@charset "utf-8";

html * { max-height:999999px; } /* чтобы не увеличивался шрифт в мобильной версии */
html * { box-sizing:border-box; } /* чтобы паддинги и бордеры не меняли размеры блока */
html * { outline:none; }

html, body { margin:0; padding:0 }
body {
  visibility: visible !important; /* открываем скрытый, до загрузки стилей, боди  */
  min-width:300px;
  overflow-x:hidden;
}
html, iframe, img { border:none; }
body, form, p, ul, h1, h2, h3, h4, pre { margin:0; }
textarea { overflow:auto; }
table { border-collapse:collapse; } /* cellspacing="0" */
td, th { padding:0 } /* cellpadding="0" */
input, select, textarea, button, img { vertical-align:middle; margin:0; }
a *, button { cursor:pointer; }
::-moz-focus-inner { border:0; padding:0; }

ul { list-style:none; margin:0; padding:0; }
ul li { margin:0; padding:0; }

* { transition:all 0.3s ease, color 0s ease; }


.text-nowrap { white-space:nowrap; }
.text-center { text-align:center; }
.text-end { text-align:right; }
.text-end-i { text-align:right !important; }
.d-flex { display:flex; align-items:center; justify-content:space-between; }
.d-none { display:none; }

.fs-12 { font-size:12px; }
.fs-13 { font-size:13px; }
.fs-18 { font-size:18px; }
.fs-22 { font-size:22px; }
.fw-600 { font-weight:600; }

.o-50 { opacity:0.50 }
.o-75 { opacity:0.75 }

.mt-04 { margin-top:4px }
.mt-06 { margin-top:6px }
.mt-08 { margin-top:8px }
.mt-12 { margin-top:12px }
.mt-24 { margin-top:24px }
.mt-40 { margin-top:40px }

.mr-04{margin-right: 4px}

.mb-04 { margin-bottom:4px }
.mb-06 { margin-bottom:6px }
.mb-08 { margin-bottom:8px }
.mb-12 { margin-bottom:12px }
.mb-16 { margin-bottom:16px }

.w-100 { width:100%; }


.text-dark { color:#7367F0 !important; }
.text-green { color:#28C76F !important; }
.text-red { color:#EA5455 !important; }


.clearfix::after { display:block; content:""; clear:both; }
