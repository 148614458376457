.invoices .data-head { word-spacing:15px; }
.invoices .data-head { display:block; }
.invoices .data-head > * { display:flex; justify-content:space-between; align-items:center; }
.invoices .data-head > * > * { max-width:50%; }
.invoices .data-head > *:not(:first-child) { margin-top:20px; }


.invoice-status
{
  display: flex; align-items: center;
  .status_icon
  {
    margin-right: 5px;
  }
}


.invoice-card .data-head { display:block; }
.invoice-card .data-head .line1 { display:flex; justify-content:space-between; align-items:center; }
.invoice-card .data-head .line1 > div { display:flex; justify-content:space-between; align-items:center; width:45%; }
.invoice-card .data-head .line1 .risk-actions { position:absolute; background:#FFF; border-radius:6px; box-shadow:0 0.25rem 1rem rgba(165, 163, 174, 0.45); min-width:160px; padding:6px 24px; margin-top:8px; }
.invoice-card .data-head .line1 .risk-actions li { padding:12px 0; }
.invoice-card .data-head .line2 { display:flex; align-items:baseline; margin-top: 30px; justify-content: space-between; flex-wrap: wrap }
.invoice-card .data-head .line2 li { display: flex; align-items: center; justify-content: space-between}
.invoice-card .data-tbl { padding:24px; }
.invoice-card .data-tbl table { border:1px solid #E8E8EA; }
.invoice-card .data-tbl table th,
.invoice-card .data-tbl table td { white-space:nowrap; }
.invoice-card .data-footer ul li { white-space:nowrap; }
.invoice-card .data-footer ul li:not(:first-child) { padding-top:12px; }
.invoice-card .data-footer ul li span { font-weight:600; float:right; padding-left:48px; }
.invoice-card .actions { float:right; }
.invoice-card .actions.data-block { padding:14px 24px 24px; margin-left:24px; }
.invoice-card .actions .btn { width:210px; display:block; margin-top:10px; }
.invoice-card .actions .razd { margin:15px 0 5px; }
@media only screen and (max-width : 1200px) {
  .invoice-card .info { width:calc(100% - 112px); }
  .invoice-card .actions .btn { width:40px; padding:0; }
  .invoice-card .actions .btn span { display:none; }
}
@media only screen and (max-width : 992px) {
  .invoice-card .data-head .line1 { display:block; }
  .invoice-card .data-head .line1 > div { width:100%; }
  .invoice-card .data-head .line1 > div:first-child { padding-bottom:20px; }
  .invoice-card .data-footer ul li span { float:none; padding-left:24px; }
}
@media only screen and (max-width : 480px) {
  .invoice-card .info { width:calc(100% - 76px); }
  .invoice-card .data-head .line1 > div { display:block; }
  .invoice-card .data-head .line1 > div:first-child { padding-bottom:0; }
  .invoice-card .data-head .line1 > div > div { padding-top:20px; }
  .invoice-card .data-head .line2 li { padding-right:0;
    margin-bottom: 10px; }
  .invoice-card .data-tbl { padding:12px; }
  .invoice-card .data-footer ul li span { padding-left:12px; }
  .invoice-card .actions.data-block { padding:2px 12px 12px; margin-left:12px; }
}
body.dark .invoice-card .data-head .line1 .risk-actions { background:#2F3349; box-shadow:0 0.25rem 1rem rgba(16, 16, 17, 0.45); }



.wallets .net { padding:24px; }
.wallets .net .head { display:flex; justify-content:space-between; align-items:center; }
.wallets .net .head i { font-size:22px; vertical-align:middle; }
.wallets .net .head > * { width:45%; display:flex; justify-content:space-between; align-items:center;  }
.coin { display:flex; align-items:center; }
.coin .logo { width:44px; height:44px; background:rgba(115, 103, 240, 0.08); border-radius:6px; display:flex; margin-right:16px; }
.coin .logo img { max-width:32px; max-height:32px; margin:auto; }
.wallets .net .head .info { display:flex; justify-content:space-between; align-items:center; width:55%; }
.wallets .net .head .bw { width:10%; }
.wallets .net .head .aml { display:flex; align-items:center; }
.wallets .net .head .aml > *:not(:first-child) { margin-left:10px; }
@media only screen and (max-width : 992px) {
  .wallets .net .head { display:block; }
  .wallets .net .head > * { width:100%; }
  .wallets .net .head .bw { height:24px; }
}
@media only screen and (max-width : 480px) {
  .wallets .net { padding:12px; }
}
@media only screen and (max-width : 444px) {
  .wallets .net .head > * { width:100%; display:block; }
  .wallets .net .head .bw { display:none; }
  .wallets .net .head > * > *:not(.coin) { margin-top:24px; }
  .wallets .net .head .info { width:100%; }
}
.wallets .net.off .head .coin,
.wallets .net.off .head .info { opacity:0.5; }
.wallets .net.off .head .aml { visibility:hidden; }
.wallets .net.off .data-tbl { display:none; }
@media only screen and (max-width : 444px) {
  .wallets .net.off .head .aml { display:none; }
}
.wallets .data-tbl { border:1px solid #E8E8EA; margin-top:24px; }
.wallets .data-tbl .name img { width:20px; }
.wallets .data-tbl .name span { display:inline-block; background:rgba(115, 103, 240, 0.08); border-radius:4px; font-size:10px; font-weight:600; padding:5px; }
.wallets .data-tbl .off .name { opacity:0.5; }
.wallets .data-tbl .off .off-hidden { visibility:hidden; }


.merchant-add { padding:0 24px 24px; }
.merchant-add .data { column-count:2; column-gap:24px; }
.merchant-add .input { width:100%; }
.merchant-add .razd { margin:24px 0; }
.merchant-add .btns_action { text-align:right; }
@media only screen and (max-width : 480px) {
  .merchant-add { padding:0 12px 12px; }
  .merchant-add .data { column-count:1; }
  .merchant-add .btns_action { text-align:center; }
}
.nets-choose .item { display: flex; align-items: center; padding-top:10px; }
.nets-choose .item .net { display:flex; justify-content:space-between; align-items:center; padding-top:17px; flex-basis: 50%; padding-right: 24px }
.nets-choose .item .tokens {position: relative; flex-grow: 1}
.nets-choose .item .tokens .for_input { padding-top:0; }
.nets-choose .item .tokens .input { width:100%; }
.nets-choose .item.off .coin,
.nets-choose .item.off .tokens { opacity:0.5; }
@media only screen and (max-width : 480px) {
  .nets-choose .item { column-count:1; }
  .nets-choose .item .tokens .for_input { padding-top:6px; }
}
