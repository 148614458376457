.modal {position:fixed; left:0; top:0; width:100%; height:100%; background:rgba(0,0,0,0.5); z-index:1999; overflow:auto; padding:24px 0}
.modal.hidden{z-index:-99; opacity:0}
.modal > div { margin:auto; background:#FFFFFF; box-shadow:0 5px 20px rgba(75, 70, 92, 0.4); border-radius:6px; padding:0 30px 65px; position:relative; max-width: 500px}
.modal i.close { position:absolute; top:5px; right:10px; left:auto; cursor:pointer; font-size:32px; opacity:0.7}
.modal i.close:hover { opacity:1}
.modal h1 { text-align:center; padding-top:65px; font-size:26px}
.modal form { max-width:100%; padding-top:10px}
.modal form .input { display:block; width:100%}
.modal .razd { height:12px; border-top:1px dotted #B9B9B9; margin:30px 0 0}
.modal .btns_action { margin-top:35px; display:flex; justify-content:center; align-items:center}
.modal .btns_action > * { margin:0 5px; width:130px}
@media only screen and (max-width : 668px) {
  .modal form { width:100%; }
  .modal > div { padding:0 5% 7%; max-width:100%; }
  .modal h1 { padding-top:7%; font-size:24px; }
}
body.dark .modal > div {background:#2F3349; box-shadow:0 2px 4px rgba(16, 16, 17, 0.3); }
body.dark .modal .razd { border-color:#A592E1; }

.modal .modal-content
{
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal .modal-header
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  margin-bottom: 18px;
}

.modal .modal-footer
{
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.modal-invoice .line_itr { margin-top:24px; }
.modal-invoice .line_itr label:not(:first-child) { margin-left:10px; white-space:nowrap; }
.modal-invoice select { margin-top:14px; }
.modal-invoice .product { display:flex; justify-content:space-between; align-items:flex-end; }
.modal-invoice .product label.for_input { padding-top:12px; }
.modal-invoice .product > div:first-child { width:40%; }
.modal-invoice .product > div { width:25%; }
.modal-invoice .product a { font-size:22px; margin-bottom:8px; }


body:not(.show-modal-net) .modal-net { display:none; }
.modal-net form { padding-top:14px; }
.modal-net .item { display:flex; justify-content:space-between; align-items:center; padding-top:10px; }
.modal-net .item .net { display:flex; align-items:center; }
.modal-net .item .net .coin { margin:0 15px; }
.modal-net .item .tokens { width:50%; }
.modal-net .item .tokens .for_input { margin-top:0; }
.modal-net .item .tokens .input { width:100%; }
.modal-net .item.off .coin,
.modal-net .item.off .tokens { opacity:0.5; }
@media only screen and (max-width : 668px) {
  .modal-net .item { display:block; }
  .modal-net .item .tokens { width:100%; }
}



.modal-token .info { margin-top:30px; border:1px solid #DBDADE; border-radius:6px; padding:20px; display:flex; justify-content:space-between; align-items:center; }
.modal-token .info .commission { display:flex; }
.modal-token .info .commission label { font-weight:600; margin-right:10px; white-space:nowrap; }
.modal-token .info .timer { width:50%; text-align:right; }
@media only screen and (max-width : 668px) {
  .modal-token .info { display:block; }
  .modal-token .info .timer { width:100%; margin-top:24px; text-align:left; }
}
body.dark .modal-token .info { border-color:#434968; }

.modal-aml form { padding-top:48px; text-align:center; }
.modal-aml form > div { padding-top:24px; }
.modal-aml form .input { display:inline-block; width:70px; }