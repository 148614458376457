body,
td,
th,
button {
  font-family: $default-font-family;
  font-size: 15px;
  font-weight: 400;
  color: rgba(75, 70, 92, 0.8);
}

body {
  min-width: 300px;
  background-color: #F8F7FA;
}

body.dark,
body.dark td,
body.dark th,
body.dark button {
  color: rgba(207, 211, 236, 0.9);
}

body.dark {
  background-color: #24283B;
}


a {
  text-decoration: none;
  color: rgba(75, 70, 92, 0.8);
}

a:hover {
  color: #000
}

body.dark a {
  color: rgba(207, 211, 236, 0.9)
}

body.dark a:hover {
  color: #FFF
}

.link {
  color: #7367F0 !important
}

a.link:hover {
  opacity: 0.8
}

h1 {
  font-size: 22px;
  font-weight: 600;
  color: rgba(75, 70, 92, 0.9)
}

@media only screen and (max-width : 480px) {
  h1 {
    font-size: 20px;
  }
}

body.dark h1 {
  color: rgba(207, 211, 236, 1)
}


.tpl_rubric {
  padding-bottom: 26px;
  flex-basis: 100%
}

@media only screen and (max-width : 480px) {
  .tpl_rubric {
    padding-bottom: 15px;
  }
}

.input {
  background-color: #FFFFFF;
  border: 1px solid #DBDADE;
  border-radius: 6px;
  height: 40px;
  padding: 0 12px;
  font-size: 15px;
  color: rgba(75, 70, 92, 0.9);
}

.input:focus {
  border-color: #7367F0 !important;
}

.input-active {
  border-color: #7367f0;
  color: #7367f0;
}

body.dark .input {
  background-color: #2F3349;
  border-color: #434968;
  color: rgba(207, 211, 236, 1)
}

.input.invalid,
body.dark .input.invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

select.input.invalid,
body.dark select.input.invalid {
  padding-right: 1.7rem;
  background-position: right calc(0.375em + 0.8rem) center
}

.btn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgb(165 163 174 / 40%);
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid;
  height: 40px;
  padding: 12px;
  line-height: 100%;
}

.btn i {
  font-size: 20px;
  vertical-align: text-bottom;
}

.btn-main {
  color: #fff !important;
  background-color: #7367f0;
  border-color: #7367f0;
}

.btn-main:hover {
  background-color: #655BCF;
}

.btn-main-rev {
  color: #7367f0 !important;
  border-color: #7367f0;
  background-color: #FFF;
}

.btn-main-rev:hover {
  border-color: #655BCF;
  color: #655BCF !important;
}

.btn-main-nobrd {
  border: none;
  background: none;
  font-weight: 600;
  font-size: 13px;
  color: #7367f0 !important;
  padding: 0;
  height: auto;
  box-shadow: none;
}

.btn-main-nobrd:hover {
  opacity: 0.8;
}

.btn-gray {
  background-color: rgba(75, 70, 92, 0.08);
  border-color: rgba(75, 70, 92, 0.08);
}

.btn-gray:hover {
  background-color: rgba(75, 70, 92, 0.2);
}

.btn-black {
  background-color: #FFF;
  border-color: #000;
  color: #000;
  opacity: 0.7;
}

.btn-black:hover {
  opacity: 0.9;
}

.btn-red {
  background-color: #FFF;
  border-color: #D84D4E;
  color: #D84D4E !important;
  opacity: 0.8;
}

.btn-red:hover {
  opacity: 1;
}

.btn-sm {
  height: 30px;
  font-size: .9rem;
}

.btn[disabled] {
  opacity: .7;
  pointer-events: none
}

body.dark .btn {
  box-shadow: 0 2px 4px rgb(15 20 34 / 40%);
}

body.dark .btn-main-rev {
  background-color: #24283B;
}

body.dark .btn-main-nobrd {
  box-shadow: none;
}

body.dark .btn-gray {
  background: rgba(75, 70, 92, 0.5);
}

body.dark .btn-gray:hover {
  background: rgba(75, 70, 92, 0.2);
}

body.dark .btn-black {
  background: #24283B;
  border-color: rgba(207, 211, 236, 0.9);
}

body.dark .btn-gray:hover {
  background: rgba(75, 70, 92, 0.2);
}

body.dark .btn-red {
  background-color: #24283B;
}


input.input-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

input.input-radio+span {
  border: 2px solid #4B465C;
  border-radius: 99px;
  width: 17px;
  height: 17px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
  opacity: 0.3;
}

input.input-radio:hover+span {
  opacity: 0.7;
}

input.input-radio:checked+span {
  border: 5px solid #7367F0;
  box-shadow: 0 2px 4px rgba(165, 163, 174, 0.3);
  opacity: 1;
}

body.dark input.input-radio:not(:checked)+span {
  border-color: #D8CCFF;
}

input.input-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

input.input-checkbox+span {
  display: inline-block;
  width: 44px;
  border-radius: 40px;
  background: #A8AAAE;
  padding: 3px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  font-size: 1px;
  text-align: start
}

input.input-checkbox+span>span {
  display: inline-block;
  background: #FFF;
  border-radius: 40px;
  width: 20px;
  height: 20px;
  font-size: 1px;
}

input.input-checkbox+span+span,
input.input-checkbox+span+span+span {
  margin-left: 7px;
}

input.input-checkbox:checked+span {
  background: #7367F0;
}

input.input-checkbox:checked+span>span {
  margin-left: 18px;
}

input.input-checkbox:checked+span+span+span {
  display: inline;
}

input.input-checkbox-small+span {
  width: 28px
}

input.input-checkbox-small+span>span {
  width: 12px;
  height: 12px
}

input.input-checkbox-small+span+span,
input.input-checkbox-small+span+span+span {
  font-size: 13px;
}

input.input-checkbox-small:checked+span>span {
  margin-left: 10px
}

input.input-checkbox-green:checked+span {
  background: #28C76F
}

.menu {
  width: 260px;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(165, 163, 174, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding: 0 14px;
  z-index: 999;
}

.menu .head {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 84px;
  height: 20px;
}

.menu .head .hide-menu {
  font-size: 24px;
  cursor: pointer;
}

.menu select {
  width: 100%;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
  font-weight: 600;
}

.menu label {
  text-transform: uppercase;
  font-size: 11px;
  display: block;
  padding: 20px 0 0 16px;
  color: rgba(75, 70, 92, 0.5);
}

.menu ul {
  padding: 5px 0 0 0;
}

.menu ul li {
  padding: 1px 0;
}

.menu ul li a {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 6px;
}

.menu ul li a i {
  font-size: 24px;
}

.menu ul li a div {
  width: 48px;
  padding: 0 0 0 8px;
  text-align: center;
}

.menu ul li a:hover {
  text-decoration: none;
}

.menu ul li a:hover:not(.disabled) {
  background-color: #F8F8F8;
}

.menu ul li a.active {
  background: linear-gradient(91.88deg, #7367F0 0%, rgba(115, 103, 240, 0.7) 100%);
  color: #FFFFFF;
}

.menu ul li a.disabled {
  opacity: 0.3;
}

.menu ul li a.disabled,
.menu ul li a.disabled * {
  cursor: not-allowed;
}

@media only screen and (min-width : 1200px) {
  .menu-short .menu:not(:hover) {
    width: 68px;
  }

  .menu-short .menu:not(:hover) .head .logo img {
    width: 40px;
  }

  .menu-short .menu:not(:hover) .head .hide-menu {
    display: none;
  }

  .menu-short .menu .head .hide-menu {
    transform: rotate(180deg);
  }

  .menu-short .menu:not(:hover) select {
    padding-left: 5px;
    padding-right: 0
  }

  .menu-short .menu:not(:hover) label {
    display: none;
  }

  .menu-short .menu:not(:hover) ul li a div {
    width: 40px;
    padding: 0;
  }

  .menu-short .menu:not(:hover) ul li a span {
    display: none;
  }

  .menu-short .main {
    padding-left: 68px;
  }
}

@media only screen and (max-width : 1200px) {
  body.menu-show {
    height: 100%;
    overflow: hidden;
  }

  body.menu-show .menu-show-bg {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  body:not(.menu-show) .menu {
    left: -260px;
  }
}

body.dark .menu {
  background: #2F3349;
  box-shadow: 0 2px 4px rgba(16, 16, 17, 0.3);
}

body.dark .mainlogo img {
  filter: invert(91%) sepia(7%) saturate(1846%) hue-rotate(190deg) brightness(100%) contrast(86%);
}

body.dark select {
  background-color: #343951;
  border-color: #343951;
}

body.dark .menu label {
  color: rgba(207, 211, 236, 0.7);
}

body.dark .menu ul li a:hover:not(.disabled) {
  background-color: #343951;
}

body:not(.dark) .theme .i_ligth {
  display: none;
}

body.dark .theme .i_dark {
  display: none;
}



.main {
  padding: 0 0 26px 260px;
  position: relative;
}

.main>div {
  max-width: 1444px;
  margin: 0 auto;
}

.main .navbar {
  height: 100px;
  position: relative;
  width: 100%;
}

.main .navbar>div {
  height: 76px;
  position: fixed;
  width: calc(100% - 260px);
  max-width: 1444px;
  top: 0;
  z-index: 990;
  padding: 16px 26px 0;
}

.main .navbar nav {
  background: #FFFFFF;
  box-shadow: 0 2px 4px rgba(165, 163, 174, 0.3);
  border-radius: 6px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main .navbar nav i {
  font-size: 26px;
}

.main .navbar nav a:hover {
  text-decoration: none;
}

.main .navbar nav .btn_menu {
  display: none;
  margin-left: 20px;
  border: none;
  background: none;
  padding: 0;
}

.main .navbar nav .items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;

}

.main .navbar nav .items>li {
  padding: 0 10px;
  cursor: pointer;
  position: relative;
}

.main .navbar nav .items .lng img {
  width: 26px;
  height: 26px;
  border-radius: 26px;
}

.main .navbar nav .items .notifications div {
  width: 18px;
  height: 18px;
  text-align: center;
  color: #FFF;
  font-size: 13px;
  font-weight: 600;
  border-radius: 18px;
  background: #EA5455;
  position: absolute;
  margin: -30px 0 0 14px;
  padding-top: 1px;
}

.main .navbar nav .items .profile button {
  height: 26px;
  font-weight: 600;
  font-size: 16px;
  background: none;
  border: none;
  padding: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main .navbar nav .items>li:not(.d_menu_show) ul {
  display: none;
}

.main .navbar nav .items ul {
  position: absolute;
  background: #FFF;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 0 0.25rem 1rem rgba(165, 163, 174, 0.45);
  right: 0;
  left: auto;
  top: 35px;
  z-index: 9999;
  min-width: 170px;
}

.main .navbar nav .items ul li a {
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 40px;
  padding-right: 12px;
}

.main .navbar nav .items ul li a div {
  width: 50px;
  text-align: center;
}

.main .navbar nav .items ul li a img {
  max-width: 26px;
  max-height: 26px;
}

.main .navbar nav .items ul li a:hover {
  text-decoration: none;
  background-color: #F4F3FE;
}

.main .navbar nav .items .profile {
  width: auto;
  min-width: 160px;
}

@media only screen and (max-width : 1200px) {
  .main .navbar nav .btn_menu {
    display: block;
  }

  .main {
    padding-left: 0
  }

  .main .navbar>div {
    width: 100%;
  }
}

@media only screen and (max-width : 480px) {
  .main .navbar {
    height: 90px;
  }

  .main .navbar>div {
    padding: 10px 15px 0;
  }

  .main .navbar nav .items .profile {
    min-width: 100px;
    max-width: 150px;
    font-size: 14px;

    button {
      font-size: 14px;
    }

  }

  .main .navbar nav .items ul li a {
    padding-right: 0
  }

  .navbar svg {
    max-width: 20px
  }
}

.main .tpl_content {
  padding: 0 26px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

@media only screen and (max-width : 480px) {
  .main .tpl_content {
    padding: 0 15px;
  }
}

body.dark .main .navbar nav {
  background: #2F3349;
  box-shadow: 0 2px 4px rgba(16, 16, 17, 0.3);
}

body.dark .main .navbar nav .items ul {
  background: #2F3349;
  box-shadow: 0 0.25rem 1rem rgba(16, 16, 17, 0.45);
}

body.dark .main .navbar nav .items ul li a:hover {
  background-color: #343951;
}