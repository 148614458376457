.data-block { background:#FFFFFF; box-shadow:0 0.25rem 1.125rem rgb(75 70 92 / 10%); border-radius:6px; }
.data-block .data-head { padding:24px; display:flex; align-items:center; justify-content:space-between; }
.data-block .data-section { padding:24px; }
.data-block .data-tbl { overflow:auto; border-top:1px solid #E8E8EA; border-bottom:1px solid #E8E8EA; }
.data-block .data-tbl .sort {
  display:inline-block;
  padding:4px 24px 4px 0;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%234B465C" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>') 100% 150% no-repeat,
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%234B465C" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>') 100% -50% no-repeat;
  background-size:16px;
}
.data-block .data-tbl .sort.asc {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%234B465C" stroke-opacity="0.8" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>'),
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%234B465C" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>');
}
.data-block .data-tbl .sort.desc {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%234B465C" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>'),
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%234B465C" stroke-opacity="0.8" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>');
}
body.dark .data-block .data-tbl .sort {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%23CFD3EC" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>'),
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%23CFD3EC" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>');
}
body.dark .data-block .data-tbl .sort.asc {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%23CFD3EC" stroke-opacity="0.8" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>'),
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%23CFD3EC" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>');
}
body.dark .data-block .data-tbl .sort.desc {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%23CFD3EC" stroke-opacity="0.2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>'),
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="%23CFD3EC" stroke-opacity="0.8" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 15l6 -6l6 6" /></svg>');
}
.data-block .data-tbl table { width:100%; }
.data-block .data-tbl table th { text-align:left; font-weight:600; font-size:13px; text-transform:uppercase; padding:12px 20px; white-space: nowrap }
.data-block .data-tbl table td { padding:12px 20px; border-top:1px solid #E8E8EA; min-height:50px; white-space: nowrap }
.data-block .data-tbl table td i { font-size:23px; vertical-align:middle; }
.data-block .data-tbl table td.id a { color:#7367F0; }
.data-block .data-tbl table td.id a:hover { color:#9E95FF; }
.data-block .data-tbl table td.actions { word-spacing:10px; white-space:nowrap; text-align:right; }
.data-block .data-footer { padding:24px; display:flex; align-items:center; justify-content:space-between; }
.data-block .data-footer .info { font-size:13px; opacity:0.8; }
@media only screen and (max-width : 992px) {
  .data-block .data-footer { display:block; text-align:center; }
  .data-block .data-footer > *:not(:first-child) { margin-top:20px; }
}
@media only screen and (max-width : 480px) {
  .data-block .data-head { display:block; padding:12px; }
  .data-block .data-head > *:not(:first-child) { margin-top:10px; }
  .data-block .data-section { padding:12px; }
  .data-block .data-tbl table th,
  .data-block .data-tbl table td { padding:12px; }
  .data-block .data-footer { padding:12px; }
  .data-block .data-footer > *:not(:first-child) { margin-top:15px; }
}
body.dark .data-block { background:#2F3349; }
body.dark .data-block .data-tbl,
body.dark .data-block .data-tbl table td { border-color:#434968; }


ul.pagination { display:flex; justify-content:flex-end; }
ul.pagination li { margin:0 2px; }
ul.pagination li a { display:block; padding:6px 8px; min-width:30px; text-align:center; background:rgba(75, 70, 92, 0.08); border-radius:6px; }
ul.pagination li a:hover { background:rgba(75, 70, 92, 0.2); }
ul.pagination li a.active { background:#7367F0 !important; color:#FFF; }
@media only screen and (max-width : 992px) {
  ul.pagination { justify-content:center; }
}
@media only screen and (max-width : 480px) {
  ul.pagination li:not(:first-child):not(:last-child) { display:none; }
}
body.dark ul.pagination li a { background:rgba(75, 70, 92, 0.5); }
body.dark ul.pagination li a:hover { background:rgba(75, 70, 92, 0.2); }

.razd { border-top:1px solid #E8E8EA; font-size:1px; margin-top: 10px; margin-bottom: 10px; }
body.dark .razd { border-color:#434968; }

label.for_input { display:flex; justify-content:space-between; padding:24px 0 4px; font-size:13px; }

.btns_action { margin-top:35px; text-align:center; }
.btns_action > * { width:130px; }
.btns_action > *:not(:first-child) { margin-left:10px; }
@media only screen and (max-width : 340px) {
  .btns_action > * { width:auto; }
  .btns_action > *:not(:first-child) { margin-left:0; }
}

